export function menuHandler() {
	/* Mobile menu */
	// if ($(window).width() < 1151) {
	// 	$('.js-show-menu').click(function() {
	// 		if ($('.js-mobile-nav').hasClass('active')) {
	// 			$('.js-mobile-nav').removeClass('active')
	// 			$.each($('.js-show-sub'), function() {
	// 				$(this)
	// 					.parent()
	// 					.removeClass('active')
	// 			})
	// 		} else {
	// 			$('.js-mobile-nav').addClass('active')
	// 		}
	// 	})
	// 	$('.js-show-sub').click(function() {
	// 		if (
	// 			$(this)
	// 				.parent()
	// 				.hasClass('active')
	// 		) {
	// 			$(this)
	// 				.parent()
	// 				.removeClass('active')
	// 		} else {
	// 			$.each($('.js-show-sub'), function() {
	// 				$(this)
	// 					.parent()
	// 					.removeClass('active')
	// 			})
	// 			$(this)
	// 				.parent()
	// 				.addClass('active')
	// 		}
	// 	})
	// }
	/* Show and hide desktop menu on scroll */
	// var lastScrollTop = 0;
	// var showMenu = true;
	// if ( $(window).width() > 1150 ) {
	// 	$(window).scroll(function(event){
	// 		var st = $(this).scrollTop();
	// 		if (st > lastScrollTop && showMenu == true && st > 100) {
	// 			showMenu = false;
	// 			$('.desktop-nav').css("transform", "translateY(-100%)");
	// 		} else if (st < lastScrollTop && showMenu == false) {
	// 			showMenu = true;
	// 			$('.desktop-nav').css("transform", "translateY(0)");
	// 		}
	// 		lastScrollTop = st;
	// 	});
	// }
}
